@import './variables';

.payment {
    &__container {
        display: flex;
        flex-direction: column;
        height: 100%;
        justify-content: space-between;
    }

    &__content {
        display: flex;
        justify-content: center;
        align-items: center;
        flex: 1;
        flex-direction: column;
    }

    &__content--right {
        display: flex;
        justify-content: center;
        flex: 1;
        flex-direction: column;
        text-align: left;
        width: 100%;
    }

    &__no-payment-details {
        margin-top: 10px;
        font-size: 18px;
        text-align: center;
    }

    &__button-container {
        position: relative;
        bottom: 0;
        width: 100%;
    }

    &__back-button {
        padding: 15px;
        color: $activeWhite;
        background: rgba(0, 0, 0, 0);
        border: 0;
        width: 100%;
        margin-top: 10px;
    }

    &__title {
        font-family: $bold;
        font-size: 24px;
        color: $activeWhite;
        margin-bottom: 10px;
    }

    &__title-preauth {
        font-family: $bold;
        font-size: 24px;
        color: $activeWhite;
        margin-bottom: 5px;
    }
}

.hidden {
    visibility: hidden;
}

.purchase-summary {
    font-size: 14px;
    margin-top: 30px;
}

.purchase-summary-title {
    font-size: 12px;
}

.purchase-station {
    font-size: 18px;
    font-family: $bold;
}

.purchase-summary-rates {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.purchase-auth-message {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 14px;
    margin-top: 5px;
    margin-bottom: 5px;
}

.purchase-summary-balance {
    margin-top: 30px;
}

.purchase-total {
    display: flex;
    justify-content: space-between;
    font-family: $bold;
    border-top: 1px solid $activeWhite;
    margin-top: 10px;
    padding-top: 10px;
}

.reload-title {
    font-family: $bold;
    font-size: 16px;
    margin-bottom: 5px;
}

.reload-dropdown {
    background: $neutralBlack;
    border-radius: 8px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 15px 20px;
    align-items: center;
    margin-bottom: 10px;
}

.reload-dropdown-icon {
    width: 15px;
}

.reload-auto {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
}

.payment-modal-close-container {
    display: flex;
    justify-content: space-between;
}

.modal__content-payment {
    background-color: $navColor;
    padding: 20px 20px 0;
    position: relative;
    width: 100%;
    transition: transform 0.5s ease;
    z-index: 1;
}

.pricing-close-container {
    margin-top: var(--top-inset);
}

.pricing-details-content {
    height: 75%;
    width: 100%;
}

.pricing-details-main-content {
    display: flex;
    flex: 1;
    flex-direction: column;
    overflow-y: auto;
    margin-bottom: 20px;
    width: 100%;
}

.pricing-details-success {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
}

.account-balance {
    display: flex;
    flex-direction: row;
    font-size: $medium;
}

.account-balance-container {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
    flex-direction: column;
}

.payment-history__item {
    padding: 20px 0 10px;
    background-color: $navColor;
    width: 100%;
    border: 0;
    color: $activeWhite;
    border-bottom: 1px solid $inactiveWhite;
}

.payment-history__header {
    display: flex;
    justify-content: space-between;
}

.payment-history__type {
    display: flex;
    align-items: center;
}

.payment-history-subscription {
    font-size: xx-small;
    border-radius: 10px;
    background-color: $inactiveWhite;
    padding: 2px 7px;
    color: $neutralBlack;
    margin-left: 5px;
}

.red {
    color: $secondaryRed;
}

.payment-history__sub {
    color: $inactiveWhite;
    font-size: x-small;
    display: flex;
    justify-content: space-between;
}

.payment-detail-station {
    padding: 20px 0;
}

.payment-detail-station-header {
    display: flex;
    justify-content: space-between;
    font-size: $medium;
}

.payment-detail-station-subheader {
    display: flex;
    justify-content: space-between;
    font-size: small;
    color: $inactiveWhite;
    margin-bottom: 20px;
}

.payment-detail-station-sub {
    display: flex;
    justify-content: space-between;
    font-size: small;
    color: $inactiveWhite;
}

.pricing-detail-item {
    padding: 10px 0;
}

.pricing-detail-border {
    border-top: 1px solid $inactiveWhite;
}

.pricing-detail-border-bottom {
    border-bottom: 1px solid $inactiveWhite;
}

.pricing-detail-item-header {
    display: flex;
    justify-content: space-between;
}

.pricing-detail-item-subheader {
    font-size: small;
    color: $inactiveWhite;
}

.payment-detail-bar {
    width: 100%;
    height: 5px;
    background-color: $inactiveWhite;
    margin: 20px 0;
}

.no-border {
    border-bottom: 0;
}

.payment-funds-container {
    border-radius: 8px;
    background-color: $sessionGray;
    margin-top: 20px;
    padding: 15px;
}

.payment-account-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.payment-account-title {
    font-size: 14px;
}

.payment-account-content {
    font-size: 24px;
    font-family: $bold;
}

.add-funds {
    background-color: $sessionGray;
    border: 2px solid $activeWhite;
    border-radius: 40px;
    color: $activeWhite;
    padding: 10px 20px;
    font-size: 12px;
    line-height: 14px;
    height: 40px;
}

.payment-title {
    font-size: 18px;
    font-family: $bold;
}
